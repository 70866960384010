import { Temporal } from '@js-temporal/polyfill'
import { orderBy, range, sortBy } from 'lodash-es'

export const JST_TIMEZONE = 'Asia/Tokyo'

export const timeOptions = (startTime = '00:00', step = 15): string[] => {
  const currentDate = Temporal.Now.plainDateISO(JST_TIMEZONE)
  const options: string[] = []
  let dateTime = currentDate.toPlainDateTime(startTime)
  do {
    options.push(dateTime.toPlainTime().toString({ smallestUnit: 'minute' }))
    dateTime = dateTime.add({ minutes: step })
  } while (currentDate.equals(dateTime))

  return options
}

type PlannedWork = {
  from: string
  to: string
  taskTemplateId: string
}

// TODO: 関数定義するファイルを見直す
export const isSamePlannedWork = (plannedWorkLeft: PlannedWork, plannedWorkRight: PlannedWork): boolean => {
  if (!Temporal.PlainTime.from(plannedWorkLeft.from).equals(plannedWorkRight.from)) {
    return false
  }

  if (!Temporal.PlainTime.from(plannedWorkLeft.to).equals(plannedWorkRight.to)) {
    return false
  }

  return plannedWorkLeft.taskTemplateId === plannedWorkRight.taskTemplateId
}

interface Work {
  id: string
  from: string
  to: string
}

export const isOverlapWork = (work: Work, workSet: readonly Work[]): boolean => {
  return workSet.some((w) => work.id !== w.id && work.to > w.from && work.from < w.to)
}

export const isOverlapWorkSet = (workSet: readonly Work[]): boolean => {
  return workSet.some((work) => {
    return isOverlapWork(work, workSet)
  })
}

// NOTE: plannedWorksの中からdefaultTimeと被っていない最小の時間を抽出
const minimumToByPlannedWorks = (
  defaultTime: Temporal.PlainTime,
  plannedWorks: readonly PlannedWork[],
): Temporal.PlainTime => {
  const beforePlannedWorks = plannedWorks.filter(
    (_) =>
      Temporal.PlainTime.compare(Temporal.PlainTime.from(_.from), defaultTime) < 1 &&
      Temporal.PlainTime.compare(Temporal.PlainTime.from(_.to), defaultTime) === 1,
  )
  if (beforePlannedWorks.length === 0) return defaultTime

  const firstPlannedWork = sortBy(beforePlannedWorks, 'from')[0]
  return minimumToByPlannedWorks(Temporal.PlainTime.from(firstPlannedWork.to), plannedWorks)
}

// NOTE: 予定入力時の候補時間をいい感じに計算
// 最後に手動入力された候補時間の直近で何も入力されていない時間が候補となる（未入力の場合はdefaultTime）
export const nextFromCandidate = (
  defaultTime: Temporal.PlainTime,
  manuallyPlannedWorks: readonly PlannedWork[],
  automaticPlannedWorks: readonly PlannedWork[],
): Temporal.PlainTime => {
  const plannedWorks = [...manuallyPlannedWorks, ...automaticPlannedWorks]
  const minimumTo =
    manuallyPlannedWorks.length === 0
      ? defaultTime
      : Temporal.PlainTime.from(orderBy(manuallyPlannedWorks, 'from', 'desc')[0].to)

  return minimumToByPlannedWorks(minimumTo, plannedWorks)
}

export const currentPlainJSTDate = (): Temporal.PlainDate => Temporal.Now.plainDateISO(JST_TIMEZONE)

export const isToday = (date: Temporal.PlainDate): boolean => {
  return date.equals(currentPlainJSTDate())
}

export const getPlainDatesInMonth = (yearMonth: Temporal.PlainYearMonth): readonly Temporal.PlainDate[] => {
  return range(1, yearMonth.daysInMonth + 1).map((day) => yearMonth.toPlainDate({ day }))
}

export const plainDateToLegacyDate = (date: Temporal.PlainDate | Temporal.PlainDateTime): Date => {
  const timestamp = date.toZonedDateTime(JST_TIMEZONE).epochMilliseconds
  return new Date(timestamp)
}

export const dayOfWeekStartingOnSunday = (date: Temporal.PlainDate): number => {
  return date.dayOfWeek % 7
}
